import type { Translation } from "../i18n-types.js";

const zh = {
  pay: "支付",
  received: "获得",
  home: {
    payLimit: "最低金额 {minimum} {currency}，最高金额 {maximum} {currency}",
    previewOrder: "预览订单",
    selectAToken: "选择代币",
    selectCurrency: "选择货币",
    selectCard: "选择银行卡",
    addDebitOrCreditCard: "添加借记卡或信用卡",
    enterAddress: "输入钱包地址",
    address: "钱包地址",
    inputCard: {
      cardNumber: "卡号",
      expiryDate: "到期日",
      securityCode: "安全码",
      holder: "持卡人",
      action: "添加卡",
    },
    buy: "购买 {symbol}",
    sell: "出售 {symbol}",
    swap: "交易闪兑",
  },
  bankTransferInstruction: {
    title: "银行转账说明",
    menuName: "银行转账",
    desc: [
      "请确认您的银行账户姓名与 KYC 信息一致。",
      "请将上述信息复制到您的银行转账凭证上。",
    ],
    sections: {
      beneficiary: {
        title: "收款人",
        items: {
          remarks: {
            title: "备注",
            desc: "此代码用于识别您的转账，请务必添加。",
          },
          IBAN: "IBAN",
          accountName: "账户名",
          accountNumber: "账号",
          address: "地址",
        },
      },
      beneficiaryBank: {
        title: "收款银行",
        items: {
          BIC_SWIFTCode: "BIC / SWIFT 代码",
          bankName: "银行名称",
          bankAddress: "银行地址",
          bankCity: "银行城市",
          bankCountry: "银行国家",
        },
      },
    },
  },
  payment: {
    method: "支付方式",
    totalToken: "总代币",
    price: "价格",
    feeByGateway: "网关手续费",
    feeByMixin: "Mixin 手续费",
    feeByNetwork: "网络手续费",
    totalAmount: "总金额",
    destination: "地址",
    tips: "您的银行可能会收取额外费用，请联系您的银行了解详情。",
  },
  login: {
    action: "使用 Mixin 登录",
    actionWithPreorder: "授权",
    actionWithEmail: "使用邮箱登录",
    email: {
      otherLoginOptions: "← 其他登录选项",
      title: "登录 Mixin Route",
      actionWithSend: "发送验证码",
      verificate: "登录",

      emailPlaceholder: "邮箱地址",
      securityCodePlaceholder: "验证码",

      verificateCodeTips: "请输入发送到 {email} 的验证码",
    },
  },
  order: {
    detail: "订单详情",
    payment: {
      fetching: "获取订单中",
      failed: "支付失败",
      paying: "支付中",
      success: "支付完成",
      successDesc: "支付完成后，代币将直接转入您的钱包。",
      expired: "价格已过期",
    },
    status: {
      returnTo: "返回 {name}",
      created: "待支付",
      paying: "进行中",
      success: "成功",
      failed: "失败",
    },
    useLatestPrice: "使用最新价格",
  },
  orders: {
    title: "订单",
    boughtSome: "购入 {symbol}",
  },
  card: {
    visa: "Visa",
    mastercard: "MasterCard",
  },
  kyc: "KYC",
  status: "状态",
  name: "Mixin Route",
  slogan: "实时购买数字货币",
  delete: "删除",
  done: "完成",
  cancel: "取消",
  retry: "重试",
  back: "返回",
  copied: "已复制",
  error: {
    10608: "持卡人姓名与您的姓名不匹配。",
    10610: "国家不一致失败，请联系客服。",
    20111: "发送邮件速率限制，请稍后重试。",
    20113: "无效的验证码。",
    20114: "验证码已过期，请重新发送。",
    20115: "无效地址。",
    20116: "价格报价已过期。",
    addCard: "添加卡失败。",
    quote: "获取最新报价失败，可能价格已过期。",
    auth: "认证失败，详情: {error}",
    kycBlocked: "KYC 不通过，请联系客服 TeamMixin。",
    pay: "支付失败",

    unknown: "未知错误，请稍后重试",
    notFound: "未找到",
    timeout: "网络超时，请稍后重试",
    params: "参数错误",

    copy: "复制到剪贴板失败",
    10601: "卡片已过期。",
    10602: "价格报价已过期。",
    10604: "不支持的卡片，请尝试其他卡片。",
    10605: "卡片同时被使用。",
    10606: "尝试次数过多，请稍后重试。",
    10607: "添加的卡片过多。",
    10609: "会话已过期。",
    network: "网络错误，请稍后重试。",
  },
  form: {
    required: "必填。",
    email: "无效的邮箱地址。",
  },
} satisfies Translation;

export default zh;
